import type {ReactElement, ReactNode} from 'react';
import React, { useEffect} from 'react';
import {ClickableDiv} from '@Components/clickable-div';
import {redirectUser} from '@Utils/browser.util';
import styles from './iframe-container.module.scss';

interface IFrameProps {
  customLink?: string;
  children: ReactNode;
}

export function IframeContainer({customLink = '', ...props}: IFrameProps): ReactElement {
  const onRedirectToCustomURL = (): void => {
    redirectUser(customLink, false, false);
  };

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.style.margin = '0';
    body.style.overflow = 'hidden';
  }, []);

  if (!customLink) {
    return <div className={styles.container}>{props.children}</div>;
  }

  return (
    <ClickableDiv className={`${styles.container} ${styles.hasCustomLink}`} onClick={onRedirectToCustomURL}>
      {props.children}
    </ClickableDiv>
  );
}
