export const onUserInactive = (onInactive: () => void, onActive: () => void, delay: number): void => {
  let time: string | number | NodeJS.Timeout | undefined;
  let isInactive = false;

  const onInactiveCallback = (): void => {
    isInactive = true;
    onInactive();
  };

  const resetTimer = (): void => {
    clearTimeout(time);
    if (isInactive) {
      isInactive = false;
      onActive();
    }
    time = setTimeout(onInactiveCallback, delay);
  };

  window.onload = resetTimer;
  // DOM Events
  document.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onmousedown = resetTimer;
  document.ontouchstart = resetTimer;
  document.onclick = resetTimer;
  document.onkeydown = resetTimer;
  document.addEventListener('scroll', resetTimer, true);

  time = setTimeout(onInactiveCallback, delay);
};
