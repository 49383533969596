import type {ReactElement} from 'react';
import React, { useEffect, useState} from 'react';
import type {PosterOpts} from '@PosterWhiteboard/poster/poster.class';
import {PosterWhiteboard} from '@Components/poster-whiteboard';
import {PosterModeType} from '@PosterWhiteboard/poster/poster-mode.class';
import LoadingPanel from '@Panels/loading-panel/loading-panel';
import {IframeContainer} from '@Components/iframe-container';
import styles from './embedded-poster.module.scss';

export interface EmbeddedPosterProps {
  posterOpts: PosterOpts;
}

interface EmbeddedPosterData {
  embeddedCustomLink: string;
  generatedPosterEmbeddedURL: string;
  hasClickableGraphicItem: boolean;
  isPosterVideo: boolean;
  posterWidthForSimplifiedSnippet: number;
  posterHeightForSimplifiedSnippet: number;
}

export function EmbeddedPoster({...props}: EmbeddedPosterProps): ReactElement | null {
  const [embeddedPosterData, setEmbeddedPosterData] = useState<EmbeddedPosterData | undefined>(undefined);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (props.posterOpts.mode.details.type !== PosterModeType.WEB_PAGE) {
      throw new Error('Incorrect poster mode, only webpage mode is aceptable for emmbedded poster');
    }

    window.PMW.readLocal('posterbuilder/loadEmbeddedPosterData', {
      posterHashId: props.posterOpts.mode.details.posterHashId,
    })
      .then((data: EmbeddedPosterData) => {
        setEmbeddedPosterData(data);
      })
      .catch(() => {
        setShowError(true);
      });
  }, []);

  const onVideoClick = (e: React.MouseEvent<HTMLVideoElement, MouseEvent>): void => {
    if (e.target) {
      const videoItem = e.target as HTMLVideoElement;
      videoItem.muted = !videoItem.muted;
    }
  };

  if (showError) {
    return <div>Oops</div>;
  }

  if (!embeddedPosterData) {
    return <LoadingPanel />;
  }

  const getPoster = (): ReactElement => {
    if (!embeddedPosterData.embeddedCustomLink && embeddedPosterData.hasClickableGraphicItem) {
      return <PosterWhiteboard className={styles.whiteboardContainer} isCenterAligned={false} posterOpts={props.posterOpts} />;
    }
    if (embeddedPosterData.isPosterVideo) {
      return (
        <video
          className="video-item"
          onClick={onVideoClick}
          src={embeddedPosterData.generatedPosterEmbeddedURL}
          autoPlay
          playsInline
          muted
          loop
          style={{
            maxWidth: embeddedPosterData.posterWidthForSimplifiedSnippet,
            maxHeight: embeddedPosterData.posterHeightForSimplifiedSnippet,
            width: '100%',
          }}
        />
      );
    }

    return (
      <img
        src={embeddedPosterData.generatedPosterEmbeddedURL}
        style={{
          maxWidth: embeddedPosterData.posterWidthForSimplifiedSnippet,
          maxHeight: embeddedPosterData.posterHeightForSimplifiedSnippet,
          width: '100%',
        }}
        alt={window.i18next.t('pmwjs_design')}
      />
    );
  };

  return <IframeContainer customLink={embeddedPosterData.embeddedCustomLink}>{getPoster()}</IframeContainer>;
}
