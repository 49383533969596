import type {ReactElement} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconShape, IconSize, IconType} from '@Components/icon-v2/icon.types';
import styles from './poster-web-page-play-overlay.module.scss';
import {useAppSelector} from '@/hooks';

export function PosterWebPagePlayOverlay(): ReactElement | null {
  const isPlaying = useAppSelector((state) => {
    return state.posterEditor.isPlaying;
  });
  const isVideo = useAppSelector((state) => {
    return state.posterEditor.isVideo;
  });

  const onPlay = (): void => {
    void window.posterEditor?.whiteboard?.play();
  };

  if (isPlaying || !isVideo) {
    return null;
  }

  return (
    <button type="button" className={`flex-center ${styles.overlay}`} onClick={onPlay}>
      <Icon icon="icon-play-video" size={IconSize.SIZE_ICON_32} shape={IconShape.CIRCLE} type={IconType.SHADOW} />
    </button>
  );
}
