import {Tooltip, TOOLTIP_POSITION} from '@Components/tooltip';
import {Text, TextSize} from '@Components/text';
import type {ReactElement} from 'react';
import React, { useRef, useState} from 'react';
import styles from './tooltip-trigger.module.scss';

export enum TooltipTriggerEvent {
  HOVER,
  CLICK,
}

export interface TooltipTriggerProps {
  text: string;
  triggerEvent?: TooltipTriggerEvent;
  overlayClass?: string;
  tooltipClass?: string;
  children: ReactElement;
  position?: TOOLTIP_POSITION;
}

function TooltipTrigger({position = TOOLTIP_POSITION.BOTTOM_MIDDLE, triggerEvent = TooltipTriggerEvent.HOVER, ...props}: TooltipTriggerProps): ReactElement {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const timer = useRef<number | undefined>();

  return (
    <div
      className={props.overlayClass ?? styles.tooltipTrigger}
      onMouseEnter={(): void => {
        if (triggerEvent === TooltipTriggerEvent.HOVER) {
          setShowTooltip(true);
        }
      }}
      onMouseLeave={(): void => {
        if (triggerEvent === TooltipTriggerEvent.HOVER) {
          setShowTooltip(false);
        }
      }}
      onClick={(): void => {
        if (triggerEvent === TooltipTriggerEvent.CLICK) {
          if (timer) {
            clearTimeout(timer.current);
          }

          setShowTooltip(true);
          timer.current = window.setTimeout(() => {
            setShowTooltip(false);
          }, 3000);
        }
      }}
    >
      {showTooltip ? <Tooltip id="tooltip" position={position} className={props.tooltipClass ?? styles.tooltip} text={<Text val={props.text} size={TextSize.XXSMALL} />} /> : null}
      {props.children}
    </div>
  );
}

export default TooltipTrigger;
