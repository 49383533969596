import type {Styles as ReactModalStyles} from 'react-modal';
import ReactModal from 'react-modal';
import './modal.scss';
import type {ReactElement, ReactNode} from 'react';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {ModalDetails} from '@Components/modal/modal-context';
import {a, useSpring} from '@react-spring/web';
import {useDrag} from '@use-gesture/react';
import {getHeightFromPixelHeight} from '@Utils/string.util';
import {checkIfModalRootShouldHideFromStore, updateHeightForModal} from '@Components/modal-container/modal-container-reducer';
import {PMW_COLORS_NEUTRAL} from '@Utils/color.util';
import {ModalErrorBoundary} from '@Components/modal/components/modal-error-boundary';
import useViewportOffset from '@Hooks/useVisualViewportScroll';
import {ReactModalDefaulClass} from '@Components/modal/modal.types';
import styles from './modal.module.scss';
import {useAppDispatch, useAppSelector} from '@/hooks';
import useWindowSize from '../../hooks/useWindowSize';
import useModalClose from './hooks/useModalClose';

export const MOBILE_MODAL_WIDTH_THRESHOLD = 781;
const DEFAULT_Z_INDEX = 5000;
const FULL_HEIGHT = '100%';
const MODAL_MAX_HEIGHT_THRESHOLD = 100;
const MAX_HEIGHT_TO_STICK_TO_BOTTOM = 800;
const BOTTOM_SHEET_CLOSE_THRESHOLD = 150;
const MODAL_MARGIN = 48;
const DEFAULT_BACKGROUND_BLUR = 4;
const DEFAULT_BACKGROUND_OPACITY = 0.7;
export const DEFAULT_ANIMATION_DURATION = 300;

ReactModal.setAppElement('body');

export interface ModalProps {
  modalId: string;
  panelId: string;
  modalHeight: string;
  modalWidth: string;
  modalMinHeight?: string;
  modalMinWidth?: string;
  modalMaxHeight?: string;
  modalMaxWidth?: string;
  stickToBottom?: boolean;
  children: ReactNode;
  /**
   * flag to turn on and off the bottom sheet on mobile screens only
   */
  isBottomSheetOnMobile?: boolean;
  /**
   * this is the height for modals on mobile screens should be passed in 'px'
   */
  mobileHeight?: string;
  snapPointsForBottomSheet?: Array<number>;
  doesBottomSheetHaveSnapPoints?: boolean;
  isBottomSheetNonBlocking?: boolean;
  bottomSheetCloseThreshold?: number;
  isOverflowVisible?: boolean;
  modalMargin?: number;
  disableDragToClose?: boolean;
  noBorder?: boolean;
  noBorderRadius?: boolean;
  className?: string;
}

export function Modal({
  isBottomSheetOnMobile = false,
  bottomSheetCloseThreshold = BOTTOM_SHEET_CLOSE_THRESHOLD,
  doesBottomSheetHaveSnapPoints = false,
  isBottomSheetNonBlocking = false,
  isOverflowVisible = false,
  modalMargin = MODAL_MARGIN,
  disableDragToClose = false,
  noBorder = false,
  noBorderRadius = false,
  ...props
}: ModalProps): ReactElement | null {
  const [modalContentEl, setModalContentEl] = useState<HTMLElement>();
  const modalData = useAppSelector((state) => {
    return state.modals.modalsHashmap[props.modalId];
  });
  const panelData = useAppSelector((state) => {
    return state.panels.sizeDataHashmap[props.panelId] ?? {};
  });
  const keyboardShift = useAppSelector((state) => {
    return state.modals.keyboardShift ?? 0;
  });
  const closeModal = useModalClose(props.panelId, props.modalId);
  const {windowWidth, windowHeight} = useWindowSize();
  const {viewportOffsetTop} = useViewportOffset();

  const maxHeightForUpperSnapPoint = windowHeight - modalMargin;
  const mediumHeightForSnapPoint = windowHeight / 2;
  const isMobileHeightInBounds = !(props.mobileHeight && getHeightFromPixelHeight(props.mobileHeight) > maxHeightForUpperSnapPoint);
  const shouldHideModalRoot = useAppSelector((state) => {
    return checkIfModalRootShouldHideFromStore(state, props.panelId);
  });
  const [isHidden, setIsHidden] = useState(false);
  const [isAnimatingClose, setIsAnimatingClose] = useState(false);
  const mobileHeightForBottomSheet = props.mobileHeight && isMobileHeightInBounds ? getHeightFromPixelHeight(props.mobileHeight) : maxHeightForUpperSnapPoint;
  const [style, api] = useSpring(() => {
    return {y: 0};
  });
  const [, setDummy] = useState(0);
  const [height, setHeight] = useState(mobileHeightForBottomSheet);
  const isDragging = useRef(false);
  const dispatch = useAppDispatch();
  const bind = useDrag(
    ({active, last, movement: [, y]}) => {
      if (!(isBottomSheetOnMobile && isMobileModal())) {
        return;
      }
      const newHeight = height + y * -1;

      if (newHeight > getHeightFromPixelHeight(getMaxHeightForBottomSheet())) {
        return;
      }

      if (last) {
        setHeight(newHeight);
        isDragging.current = false;
      }

      if (active) {
        isDragging.current = true;
        setDummy(y);
      }

      void api.start({
        y: active ? y : 0,
        immediate: true,
      });
    },
    {
      axis: 'y',
      rubberband: true,
    }
  );

  useEffect((): void => {
    dispatch(updateHeightForModal({modalId: props.modalId, height}));
  }, [height]);

  useEffect((): void => {
    if (!props.snapPointsForBottomSheet) {
      return;
    }
    setHeight(getNearestSnapPoint(height));
  }, [props.snapPointsForBottomSheet]);

  useEffect((): void => {
    if (props.mobileHeight && !isDragging.current) {
      const newHeight = Number(props.mobileHeight.replace('px', ''));
      if (newHeight > maxHeightForUpperSnapPoint) {
        setHeight(maxHeightForUpperSnapPoint);
      } else {
        setHeight(newHeight);
      }
    }
  }, [props.mobileHeight]);

  useEffect((): void => {
    if (!isDragging.current) {
      if (height <= bottomSheetCloseThreshold) {
        onModalClose();
        return;
      }
      const nearestSnapPoint = getNearestSnapPoint(height);
      if (nearestSnapPoint > maxHeightForUpperSnapPoint) {
        setHeight(maxHeightForUpperSnapPoint);
      } else {
        setHeight(getNearestSnapPoint(height));
      }
    }
  }, [isDragging.current]);

  useEffect(() => {
    if (shouldHideModalRoot) {
      setIsAnimatingClose(true);
      setTimeout(() => {
        setIsHidden(true);
        setIsAnimatingClose(false);
      }, DEFAULT_ANIMATION_DURATION);
    } else {
      setIsHidden(false);
    }
  }, [shouldHideModalRoot]);

  const getNearestSnapPoint = (val: number): number => {
    if (!doesBottomSheetHaveSnapPoints) {
      return mobileHeightForBottomSheet;
    }

    const snapPoints = props.snapPointsForBottomSheet ?? [mediumHeightForSnapPoint, maxHeightForUpperSnapPoint];
    const differences = [];
    for (const eachSnapPoint of snapPoints) {
      differences.push(Math.abs(val - eachSnapPoint));
    }
    const smallest = Math.min(...differences);
    return snapPoints[differences.indexOf(smallest)];
  };

  const getHeightForBottomSheet = (): number => {
    if (style.y && isDragging.current) {
      return height + Number(style.y.get()) * -1;
    }
    return height;
  };

  if (!modalData) {
    return null;
  }

  const getModalZIndex = (): number => {
    const modals = $('.modal');
    let maxLegacyModalZIndex = 0;
    let zIndex = DEFAULT_Z_INDEX + modalData.order;

    for (let i = 0; i < modals.length; i++) {
      const modalZIndex = parseInt($(modals[i]).css('z-index'), 10);

      if (modalZIndex > maxLegacyModalZIndex) {
        maxLegacyModalZIndex = modalZIndex;
      }
    }

    if (maxLegacyModalZIndex >= zIndex) {
      zIndex = maxLegacyModalZIndex + 1;
    }

    return zIndex;
  };

  const getHeight = (): string | number => {
    if (isBottomSheetOnMobile && isMobileModal() && props.mobileHeight) {
      return getHeightForBottomSheet();
    }

    if (isMobileModal() && props.mobileHeight) {
      return props.mobileHeight;
    }

    return props.modalHeight;
  };

  const getVariableOpacityForOverlay = (): number => {
    return (getHeightForBottomSheet() / windowHeight) * DEFAULT_BACKGROUND_OPACITY;
  };

  const getBlurValue = (): number => {
    const maxBlur = DEFAULT_BACKGROUND_BLUR;
    const x = getHeightForBottomSheet() / windowHeight;
    const weight = 3;

    return (maxBlur * (Math.exp(weight * x) - 1)) / (Math.exp(weight) - 1);
  };

  const getMaxHeightForBottomSheet = (): string => {
    if (!props.mobileHeight) {
      return 'initial';
    }

    if (!doesBottomSheetHaveSnapPoints) {
      return props.mobileHeight;
    }

    const snapPoints = props.snapPointsForBottomSheet ?? [mediumHeightForSnapPoint, maxHeightForUpperSnapPoint];
    const max = Math.max(...snapPoints);

    if (max > maxHeightForUpperSnapPoint) {
      return `${maxHeightForUpperSnapPoint}px`;
    }

    return `${max}px`;
  };

  const getMaxHeightForModal = (): string => {
    if (isMobileModal() && isBottomSheetOnMobile) {
      return getMaxHeightForBottomSheet();
    }

    if (props.modalMaxHeight) {
      return props.modalMaxHeight;
    }

    if (isMobileModal()) {
      return `calc(100% - ${modalMargin}px`;
    }

    return 'initial';
  };

  const getOverlayBackground = (): string | undefined => {
    if (isMobileModal() && isBottomSheetOnMobile) {
      if (isBottomSheetNonBlocking) {
        return undefined;
      }

      return `rgba(44, 43, 68, ${getVariableOpacityForOverlay()})`;
    }

    return `rgba(44, 43, 68, ${DEFAULT_BACKGROUND_OPACITY})`;
  };

  const getOverlayBlurValue = (): string | undefined => {
    if (isMobileModal() && isBottomSheetOnMobile) {
      if (isBottomSheetNonBlocking) {
        return undefined;
      }

      return `blur(${getBlurValue()}px)`;
    }

    return `blur(${DEFAULT_BACKGROUND_BLUR}px)`;
  };

  const getModalContainerBorderRadius = (): string => {
    if (noBorderRadius) {
      return '0';
    }
    if (isMobileModal()) {
      return '16px 16px 0 0';
    }
    if (stickModalToBottom()) {
      return '4px 4px 0 0';
    }
    return '';
  };

  const getMinWidth = (): string => {
    if (isMobileModal() && isBottomSheetOnMobile) {
      return '100%';
    }

    return props.modalMinWidth ?? 'inital';
  };

  const getBorder = (): string => {
    if (noBorder) {
      return 'none';
    }
    return `1px solid ${PMW_COLORS_NEUTRAL.NEUTRAL_6}`;
  };

  const getCustomStyles = (): ReactModalStyles => {
    const customModalStyles: ReactModalStyles = {
      content: {
        background: '#fff',
        position: 'initial',
        right: 'auto',
        bottom: 'auto',
        padding: '0',
        inset: 'initial',
        border: getBorder(),
        overflow: isOverflowVisible ? 'visible' : 'hidden',
        height: getHeight(),
        width: isMobileModal() ? '100%' : props.modalWidth,
        minHeight: isMobileModal() && isBottomSheetOnMobile ? undefined : props.modalMinHeight ?? 'inital',
        minWidth: getMinWidth(),
        maxHeight: getMaxHeightForModal(),
        maxWidth: props.modalMaxWidth ?? 'inital',
        borderRadius: getModalContainerBorderRadius(),
        transition:
          isMobileModal() && isBottomSheetOnMobile && isDragging.current
            ? ''
            : 'max-height 0.3s ease-in-out, width 0.3s ease-in-out, height 0.3s ease-in-out, max-width 0.3s ease-in-out',
        boxShadow: isMobileModal() && isBottomSheetOnMobile && isBottomSheetNonBlocking ? '0 2px 16px rgba(20, 19, 37, 0.15)' : undefined,
      },
      overlay: {
        position: 'fixed',
        height: isBottomSheetOnMobile && isMobileModal() && isBottomSheetNonBlocking ? getHeight() : undefined,
        background: getOverlayBackground(),
        backdropFilter: getOverlayBlurValue(),
        zIndex: getModalZIndex(),
        flexDirection: 'column',
        transition:
          isMobileModal() && isBottomSheetOnMobile && !isDragging.current
            ? 'background 0.05s ease-in-out, height 0.3s ease-in-out, backdrop-filter 0.05s ease-in-out, -webkit-backdrop-filter 0.05s ease-in-out'
            : '',
        top: isBottomSheetOnMobile && isMobileModal() && isBottomSheetNonBlocking ? 'unset' : '0px',
        bottom: '0px',
        left: '0px',
        right: '0px',
        WebkitBackdropFilter: getOverlayBlurValue(),
        transform: isBottomSheetOnMobile && isMobileModal() ? `translateY(-${Math.max(0, keyboardShift - (viewportOffsetTop || 0))}px)` : '',
      },
    };

    if (stickModalToBottom()) {
      if (customModalStyles.overlay && customModalStyles.content) {
        customModalStyles.overlay.justifyContent = 'end';
        customModalStyles.content.maxHeight = `calc(100% - ${modalMargin !== 0 ? styles.modalMargin : 0})`;
      }
    }

    return customModalStyles;
  };

  const stickModalToBottom = (): boolean => {
    if (props.stickToBottom) {
      return true;
    }

    if (!modalContentEl) {
      return false;
    }

    const modalHeight = $(modalContentEl).height();
    if (modalHeight === undefined) {
      return false;
    }
    if (isModalMaxHeight(modalHeight)) {
      return modalHeight > windowHeight - MODAL_MAX_HEIGHT_THRESHOLD && windowHeight < MAX_HEIGHT_TO_STICK_TO_BOTTOM;
    }
    return false;
  };

  const getPanelDataHeight = (): number => {
    return panelData.height?.length ? parseInt(panelData.height?.replace('px', ''), 10) : 0;
  };

  const isModalMaxHeight = (modalHeight: number): boolean => {
    return panelData.height === FULL_HEIGHT || (getPanelDataHeight() ?? modalHeight) > windowHeight - MODAL_MAX_HEIGHT_THRESHOLD;
  };

  const onModalClose = (): void => {
    if (modalData.isDismissible) {
      closeModal();
    }
  };

  const setHeightToDefaultForBottomSheet = (): void => {
    setHeight(mobileHeightForBottomSheet);
  };

  const onAfterClose = (): void => {
    setHeightToDefaultForBottomSheet();
    removeBodyOpenClass();
  };

  const removeBodyOpenClass = (): void => {
    $('body').removeClass('ReactModal__Body--open');
  };

  const isMobileModal = (): boolean => {
    return windowWidth < MOBILE_MODAL_WIDTH_THRESHOLD;
  };

  const getModal = (): ReactElement => {
    return (
      <ReactModal
        {...modalData}
        portalClassName={`${modalData.portalClassName ?? ''} ${isHidden ? '_hidden' : ''} ${isMobileModal() && isBottomSheetOnMobile ? styles.bottomSheet : ''} ${
          isMobileModal() ? ReactModalDefaulClass.PORTAL_SMALL_SCREEN_BLOCKING : ''
        } ${isMobileModal() && isBottomSheetOnMobile ? ReactModalDefaulClass.PORTAL_SMALL_SCREEN_NON_BLOCKING : ''}`}
        className={`${props.className} ${isAnimatingClose ? ReactModalDefaulClass.CONTENT_BEFORE_CLOSE : ReactModalDefaulClass.CONTENT_AFTER_OPEN}`}
        style={getCustomStyles()}
        onRequestClose={onModalClose}
        closeTimeoutMS={DEFAULT_ANIMATION_DURATION}
        contentRef={setModalContentEl}
        preventScroll
        onAfterClose={onAfterClose}
        shouldCloseOnOverlayClick={!isBottomSheetNonBlocking}
        overlayClassName={`${isAnimatingClose ? ReactModalDefaulClass.OVERLAY_BEFORE_CLOSE : ReactModalDefaulClass.OVERLAY_AFTER_OPEN}`}
        ariaHideApp={false}
      >
        {isMobileModal() && isBottomSheetOnMobile && !disableDragToClose ? (
          <a.div {...bind()} className={styles.handle}>
            <div className={`${styles.dragHandle} radius-4 `} />
          </a.div>
        ) : null}
        <ModalErrorBoundary modalId={props.modalId}>{props.children}</ModalErrorBoundary>
      </ReactModal>
    );
  };

  return (
    <ModalDetails.Provider
      value={useMemo(() => {
        return {isMobileModal: isMobileModal(), panelId: props.panelId};
      }, [isMobileModal(), props.panelId])}
    >
      {getModal()}
    </ModalDetails.Provider>
  );
}
