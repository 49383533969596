import type {ReactElement} from 'react';
import React, { useRef} from 'react';
import type {PosterOpts} from '@PosterWhiteboard/poster/poster.class';
import {PosterWhiteboard} from '@Components/poster-whiteboard';
import {PosterWebPagePlayOverlay} from '@Components/poster-webpage/components/poster-web-page-play-overlay';
import {useFullscreen} from 'ahooks';
import styles from './poster-webpage.module.scss';
import {PosterWebPageNavBar} from './components/poster-web-page-nav-bar';

export interface PosterWebPageConfig {
  canEditPoster: boolean;
}

export interface PosterWebpageProps {
  className?: string;
  posterOpts: PosterOpts;
  posterWebPageConfig: PosterWebPageConfig;
}

export function PosterWebpage({className = '', ...props}: PosterWebpageProps): ReactElement | null {
  const posterWebpageRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, {toggleFullscreen}] = useFullscreen(posterWebpageRef);

  return (
    <div className={`flex-center ${styles.webpage} ${className}`} ref={posterWebpageRef}>
      <PosterWhiteboard className={styles.whiteboard} posterOpts={props.posterOpts} />
      <React.StrictMode>
        <PosterWebPageNavBar isFullScreen={isFullscreen} onToggleFullScreen={toggleFullscreen} canEditPoster={props.posterWebPageConfig.canEditPoster} />
        <PosterWebPagePlayOverlay />
      </React.StrictMode>
    </div>
  );
}
