import type {ReactNode, Ref} from 'react';
import React from 'react';
import styles from './panel-content.module.scss';

export interface PanelContentProps {
  children?: ReactNode;
  className?: string;
}

export const PanelContent = React.forwardRef(({className = '', children}: PanelContentProps, ref: Ref<HTMLDivElement>) => {
  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
});
