import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import {Thumbnail, THUMBNAIL_TYPE} from '@Components/thumbnail';
import LogoFull from '@Assets/logo-postermaker-light.svg';
import {Button, Size, Type} from '@Components/button';
import {onUserInactive} from '@Utils/activity.util';
import TooltipTrigger from '@Components/tooltip-trigger';
import {TOOLTIP_POSITION} from '@Components/tooltip';
import {TooltipTriggerEvent} from '@Components/tooltip-trigger/tooltip-trigger';
import {redirectUser} from '@Utils/browser.util';
import styles from './poster-web-page-nav-bar.module.scss';

const INACTIVE_USER_DELAY = 6000;

interface PosterWebPageNavBarProps {
  canEditPoster: boolean;

  isFullScreen: boolean;

  onToggleFullScreen(): void;
}

export function PosterWebPageNavBar({...props}: PosterWebPageNavBarProps): ReactElement {
  const [isNavBarVisible, setIsNavBarVisible] = useState(true);

  useEffect(() => {
    onUserInactive(hideNavBar, showNavBar, INACTIVE_USER_DELAY);
  }, []);

  const hideNavBar = (): void => {
    setIsNavBarVisible(false);
  };

  const showNavBar = (): void => {
    setIsNavBarVisible(true);
  };

  const onToggleFullScreen = (): void => {
    props.onToggleFullScreen();
  };

  const onCopyShareableLink = (): void => {
    const link = window.posterEditor?.whiteboard?.getWebpageLink();
    if (link) {
      window.PMW.copyTextToClipboard(link);
    }
  };

  const onEdit = (): void => {
    const link = window.posterEditor?.whiteboard?.getEditLink();
    if (link) {
      redirectUser(link, false, true);
    }
  };

  return (
    <div id="nav" className={`${styles.nav} ${isNavBarVisible ? styles.navIn : ''} flex-justify-between flex-items-center`}>
      <Thumbnail
        data={{
          type: THUMBNAIL_TYPE.IMAGE,
          imageSrc: LogoFull as string,
        }}
        onClick={(): void => {
          window.location.href = window.PMW.util.site_url('');
        }}
        className={`${styles.logo} spacing-m-l-2`}
      />
      <div className="flexbox">
        {props.canEditPoster ? (
          <Button onClick={onEdit} customClasses="spacing-m-r-2" size={Size.SMALL} type={Type.DARK_GHOST} text={window.i18next.t('pmwjs_edit')} icon="icon-pencil" />
        ) : null}
        <Button
          onClick={onToggleFullScreen}
          customClasses="spacing-m-r-2"
          size={Size.SMALL}
          type={Type.DARK_GHOST}
          text={props.isFullScreen ? window.i18next.t('pmwjs_exit_fullscreen') : window.i18next.t('pmwjs_fullscreen')}
          icon={props.isFullScreen ? 'icon-exit-fullscreen' : 'icon-fit-to-screen'}
        />
        <TooltipTrigger text={window.i18next.t('pmwjs_link_copied')} position={TOOLTIP_POSITION.TOP_MIDDLE} triggerEvent={TooltipTriggerEvent.CLICK}>
          <Button onClick={onCopyShareableLink} size={Size.SMALL} type={Type.DARK_GHOST} text={window.i18next.t('pmwjs_copy_shareable_link')} icon="icon-link" />
        </TooltipTrigger>
      </div>
    </div>
  );
}
