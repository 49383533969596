import type {ReactElement} from 'react';
import React, {useEffect, useState} from 'react';
import App from '@Components/app';
import {LoadingToast} from '@Components/loading-toast';
import {createRoot} from 'react-dom/client';
import type {PosterOpts} from '@PosterWhiteboard/poster/poster.class';
import {PosterWebpageFullscreen} from '@Components/poster-webpage-fullscreen';
import type {PosterWebPageConfig} from '@Components/poster-webpage';
import {EmbeddedPoster} from '@Components/embedded-poster';

interface ReactPageProps {
  isEmbbed: boolean;
  posterWebPageConfig: PosterWebPageConfig;
  posterOpts: PosterOpts;
}

const reactPageProps = window.reactPageProps as ReactPageProps;

function PosterMakerApp(): ReactElement | null {
  const [i18Loaded, setI18Loaded] = useState(false);

  useEffect(() => {
    window.PMW.initI18next(window.PMW.LANGUAGE, () => {
      setI18Loaded(true);
    });
    window.PMW.initTracking();
  }, []);

  if (!i18Loaded) {
    return <App />;
  }

  const getPoster = (): ReactElement => {
    return reactPageProps.isEmbbed ? (
      <EmbeddedPoster posterOpts={reactPageProps.posterOpts} />
    ) : (
      <PosterWebpageFullscreen posterWebPageConfig={reactPageProps.posterWebPageConfig} posterOpts={reactPageProps.posterOpts} />
    );
  };

  return (
    <App nonStrickModeChildren={getPoster()}>
      <LoadingToast />
    </App>
  );
}

const a = document.createElement('div');
a.setAttribute('id', 'pmw-app');
document.body.appendChild(a);

const root = createRoot(document.getElementById('pmw-app')!);
root.render(<PosterMakerApp />);
