import type {ReactElement} from 'react';
import React from 'react';
import type { PosterWebpageProps} from '@Components/poster-webpage';
import {PosterWebpage} from '@Components/poster-webpage';
import styles from './poster-webpage-fullscreen.module.scss';

type PosterWebPagePageProps = PosterWebpageProps;

export function PosterWebpageFullscreen({...props}: PosterWebPagePageProps): ReactElement | null {
  return (
    <div className={styles.container}>
      <PosterWebpage {...props} />
    </div>
  );
}
